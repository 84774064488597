import _omit from 'lodash/omit';

export const mergeEnvVars = (env, vars) => {
  const final = _omit(vars, ['development', 'production', 'staging']);
  return { ...final, ...vars[env] };
};

export const sprintf = (str, options = []) => Object.keys(options)
  .reduce((acc, curr) => (acc.replace(new RegExp(`(\\{${curr}\\})`), options[curr])), str);

export const htmlEntities = (str) => String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;');

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const hexToRGBA = (h, opacity = 1) => {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1] + h[1]}`;
    g = `0x${h[2] + h[2]}`;
    b = `0x${h[3] + h[3]}`;

  // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1] + h[2]}`;
    g = `0x${h[3] + h[4]}`;
    b = `0x${h[5] + h[6]}`;
  }

  return `rgba(${+r}, ${+g}, ${+b}, ${opacity})`;
};

export const getLocal = (key, parse = false, defaults = null) => {
  let val = window.localStorage.getItem(key);
  val = parse ? JSON.parse(val) : val;
  val = (val == null) ? defaults : val;

  return val;
};

export const setLocal = (key, val, stringify = false, remove = false) => {
  if (remove) {
    window.localStorage.removeItem(key);
    return;
  }

  val = stringify ? JSON.stringify(val) : val;
  window.localStorage.setItem(key, val);
};

export const getBearerToken = (headers) => {
  let token = headers.Authorization || headers.authorization;

  if (token) {
    token = token.split(/Bearer:?\s?/i);

    return token[token.length > 1 ? 1 : 0].trim();
  }

  return null;
};

export const roundTo = (number, decimal = 2) => {
  const divider = 10 ** decimal;
  return Math.round(number * divider) / divider;
};

export const getLocation = (href) => {
  const a = document.createElement('a');
  a.href = href;
  return a;
};

export const setTimeoutPromise = (callback, timeout) => new Promise((resolve) => setTimeout(() => {
  resolve(callback());
}, timeout));

export const setIntervalPromise = (callback, interval) => new Promise((resolve) => setInterval(() => {
  resolve(callback());
}, interval));

export const inViewport = ($el, $container = null) => {
  const rect = $el.getBoundingClientRect();
  let bottom;
  let right;

  if ($container) {
    bottom = $container.clientHeight;
    right = $container.clientWidth;
  } else {
    bottom = window.innerHeight || document.documentElement.clientHeight;
    right = window.innerWidth || document.documentElement.clientWidth;
  }

  return (
    rect.top >= 0 && rect.left >= 0
    && rect.bottom <= bottom && rect.right <= right
  );
};

export const getInfiniteScrollThreshold = (offset = 460, $container) => {
  const scrollEnd = (
    ($container || document.body).scrollHeight
    - ($container ? $container.clientHeight : window.innerHeight)
  );

  return window.scrollY > (scrollEnd - offset);
};

export const generateId = (base = 16, length = 5) => (Date.now() + Math.random().toString(base).substring(2, length));

export const randomAlphaNum = (length, hasDash = true) => {
  let result = '';
  const chars = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789${
    hasDash ? '-_' : ''}`;

  for (let i = 0; i < length; i += 1) {
    result += chars[(Math.floor(Math.random() * chars.length))];
  }

  return result;
};

export const readAsDataURLPromise = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader);
  reader.onerror = reject;
  reader.onabort = reject;
  reader.readAsDataURL(file);
});

export const getImageDimensions = (file) => new Promise((resolve, reject) => {
  const url = window.URL.createObjectURL(file);
  const img = new Image();

  img.onload = () => {
    resolve({
      width: img.width,
      height: img.height,
    });
    window.URL.revokeObjectURL(url);
  };

  img.onerror = () => {
    reject(new Error('abort'));
    window.URL.revokeObjectURL(url);
  };

  img.onabort = () => {
    reject(new Error('abort'));
    window.URL.revokeObjectURL(url);
  };

  img.src = url;
});

export const getVideoMetadata = (file) => new Promise((resolve, reject) => {
  const url = window.URL.createObjectURL(file);
  const video = document.createElement('video');

  video.onloadedmetadata = () => {
    let duration;
    let bitrate;

    if (video.duration != null) {
      duration = Number(video.duration.toFixed(2));
      bitrate = Number((file.size / video.duration).toFixed(2));
    }

    resolve({
      width: video.videoWidth,
      height: video.videoHeight,
      duration,
      bitrate,
      video,
    });
    window.URL.revokeObjectURL(url);
  };

  video.onerror = () => {
    reject(new Error('abort'));
    window.URL.revokeObjectURL(url);
  };

  video.onabort = () => {
    reject(new Error('abort'));
    window.URL.revokeObjectURL(url);
  };

  video.src = url;
});

export const getItemsGroupedByType = (items = []) => items.reduce((prev, curr) => {
  if (curr.type === 'SECTION') {
    curr.children.length && prev.push({ type: 'SECTION', section: curr });
  } else {
    const last = prev.length - 1;

    if (!prev[last] || prev[last].type === 'SECTION') {
      prev.push({ type: 'ITEMS', items: [] });
    }

    prev[prev.length - 1].items.push(curr);
  }

  return prev;
}, []);
