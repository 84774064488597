<script>
export default {
  static: {
    messages: {
      WHO_ARE_YOU: 'Quel profil vous décrit le mieux ?',
      WHAT_CERTIFICATIONS: 'Avez-vous des certifications ou des labels ? CPF, Qualiopi, PSI, TOSA, RNCP...',
      WHAT_MAIN_ACTIVITY: 'Quelle est votre thématique principale ?',
      HOW_FOUND_US: 'Comment nous avez-vous connus ?',
      WHAT_TOOL: 'Quel outil utilisez-vous actuellement pour héberger vos formations ?',
      WHAT_IMPORTANT: 'Le plus important pour vous c\'est...',
    },
    questions: [
      {
        type: 'radio',
        name: 'HOW_FOUND_US',
        options: {
          REFERRAL: 'Bouche à oreille <br> <span class="has-text-weight-normal is-size-8">Un proche, réseau...</span>',
          SEO: 'Recherche <br> <span class="has-text-weight-normal is-size-8">Google, Bing...</span>',
          ARTICLE: 'Article  <br> <span class="has-text-weight-normal is-size-8">Blog, site web...</span>',
          LINKEDIN: 'LinkedIn',
          FACEBOOK: 'Facebook <br> <span class="has-text-weight-normal is-size-8">Groupe, page, pub...</span>',
          INSTAGRAM: 'Instagram',
          EDTECH: 'EdTech France',
          BLOG: 'Événement <br> <span class="has-text-weight-normal is-size-8">Salon, webinaire...</span>',
          OTHER: 'Autre</span>',
        },
      }, {
        type: 'radio',
        name: 'WHO_ARE_YOU',
        options: {
          TEACHER_PRO: 'Formateur pro <br> <span class="has-text-weight-normal is-size-8">DREETS, Qualiopi...</span>',
          TEACHER_NOT_PRO: 'Formateur indé <br> <span class="has-text-weight-normal is-size-8">non-référencé(e)</span>',
          IN_COMPANY: 'Pédagogie interne <br> <span class="has-text-weight-normal is-size-8">entreprise, association</span>',
          TEACHER_IN_SCHOOLL: 'Enseignant(e) <br> <span class="has-text-weight-normal is-size-8">école, lycée, fac...</span>',
          TEACHER_HOME: 'Prof particulier',
          INFOPRENEUR: 'Infopreneur <br> <span class="has-text-weight-normal is-size-8">création de contenus...</span>',
          COACH: 'Coach <br> <span class="has-text-weight-normal is-size-8">accompagements...</span>',
          THERAPIST: 'Thérapeute <br> <span class="has-text-weight-normal is-size-8">bien-être, kiné, psy...</span>',
          OTHER: 'Autre <br> <span class="has-text-weight-normal is-size-8">consultant, prestataire..</span>',
        },
      }, {
        type: 'radio',
        name: 'WHAT_CERTIFICATIONS',
        options: {
          YES: 'Oui <br> <span class="has-text-weight-normal is-size-8">Précisez lesquels</span>',
          SOON: 'Non (c\'est prévu) <br> <span class="has-text-weight-normal is-size-8">Précisez lesquels</span>',
          NO: 'Non <br> <span class="has-text-weight-normal is-size-8">Précisez pourquoi</span>',
        },
      }, {
        type: 'radio',
        name: 'WHAT_MAIN_ACTIVITY',
        options: {
          HEALTH: 'Santé <br> Bien-être',
          BUSINESS_MANAGEMENT: 'Gestion d\'entreprise',
          IT: 'Informatique <br> Multimédia Logiciels',
          PERSONAL_DEVELOPMENT: 'Développement personnel',
          BEAUTY: 'Beauté <br> Esthétique <br> Soins',
          LANGUAGE: 'Langues <br> Traduction',
          MARKETING_COM: 'Marketing <br> Communication',
          FINANCE_INVESTMENT: 'Investissement <br> Finance',
          ART_CULTURE: 'Arts <br> Culture',
          IMMO: 'Immobilier',
          SPORT: 'Sport <br> Activité physique',
          OTHER: 'Autre <br> <span class="has-text-weight-normal is-size-8">Précisez plus bas</span>',
        },
      }, {
        type: 'radio',
        name: 'WHAT_TOOL',
        options: {
          LMS: 'Un LMS',
          MARKEPLACE: 'Une marketplace <br> <span class="has-text-weight-normal is-size-8">Udemy, ...</span>',
          SAAS: 'Plateforme en ligne <br> <span class="has-text-weight-normal is-size-8">Précisez laquelle</span>',
          WEBSITE: 'Un site sur-mesure',
          OTHER: 'Autre chose',
          NOTHING: 'Rien pour le moment',
        },
      }, {
        type: 'radio',
        name: 'WHAT_IMPORTANT',
        options: {
          SIMPLE: 'Simple à utiliser <br> <span class="has-text-weight-normal is-size-8">pas de prise de tête...</span>',
          QUICK_SUPPORT: 'Support réactif <br> <span class="has-text-weight-normal is-size-8">réponse en 24h ouvrée</span>',
          HUMAIN_SUPPORT: 'Support humain <br> <span class="has-text-weight-normal is-size-8">parler à une personne et pas un chatbot</span>',
          CONTENT: 'Pédagogie <br> <span class="has-text-weight-normal is-size-8">avoir plusieurs choix de contenu</span>',
          PRICE: 'Le prix',
          SALES: 'Fonctionnalités de vente & paiements <br> <span class="has-text-weight-normal is-size-8">avoir plusieurs façons de vendre</span>',
          FRANCE: 'Outil français <br> <span class="has-text-weight-normal is-size-8">entreprise, service client, données...</span>',
          LIMITS: 'Pas de limite <br> <span class="has-text-weight-normal is-size-8">avoir un nombre de formations ou apprenants illimités</span>',
          COMMUNAUTE: 'Communauté <br> <span class="has-text-weight-normal is-size-8">avoir des fonctionnalités de communauté en ligne</span>',
        },
      },
    ],
  },
  props: {
    isMobileScreen: {
      type: Boolean,
      defalt: false,
    },
  },
  data: () => ({
    isLoading: false,
    hasError: false,
    currentQuestion: 0,
    questions: [],
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    questionHasError() {
      const current = this.questions[this.currentQuestion];

      if ((current.type === 'checkbox'
        ? (!current.value.length || (current.value.includes('OTHER') && !current.other))
        : (
          !current.value
          || (current.value === 'OTHER' && !current.other)
          || (current.name === 'WHAT_TOOL' && current.value !== 'NOTHING' && !current.other)
        )
      )) {
        this.hasError = true;
        this.$nextTick(() => this.$refs.modalBody.scroll(0, this.$refs.modalBody.scrollHeight));
        return true;
      }

      return false;
    },
    handle() {
      if (this.currentQuestion < this.questions.length - 1) {
        this.nextQuestion();
        return;
      }

      if (this.questionHasError()) {
        return;
      }

      let onboarding_questions = this.questions.reduce((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {});

      onboarding_questions = JSON.stringify({ ...onboarding_questions });

      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { onboarding_questions })
        .then(() => this.$router.replace({ query: {} }))
        .finally(() => (this.isLoading = false));
    },
    nextQuestion() {
      if (this.questionHasError()) {
        return;
      }

      this.currentQuestion++;
      this.hasError = false;
    },
    prevQuestion() {
      this.currentQuestion--;
      this.hasError = false;
    },
  },
  created() {
    this.questions = this.$options.static.questions
      .map((question) => ({
        name: question.name,
        type: question.type,
        value: question.type === 'checkbox' ? [] : '',
        other: '',
      }));
  },
};
</script>

<template>
  <div v-if="authStore.onboarding_questions" class="compoq modal-card has-text-centered">
    <header class="modal-card-head">
      <h1 class="modal-card-title mw-full break-words">
        Bonjour {{ authUser.firstname }}
      </h1>
    </header>
    <div class="modal-card-body">
      <p>Vous êtes sur notre questionnaire, et vous y avez déjà répondu !</p>
      <p v-show="isMobileScreen" class="mt-5">
        <b-button type="is-primary" tag="router-link" :to="{name: 'home'}" replace>
          Retourner à l'accueil
        </b-button>
      </p>
    </div>
    <footer v-show="!isMobileScreen" class="modal-card-foot is-justify-content-center">
      <b-button type="is-primary" tag="router-link" :to="{name: 'home'}" replace>
        Retourner à l'accueil
      </b-button>
    </footer>
  </div>
  <form v-else class="modal-card" @submit.prevent="handle">
    <header class="modal-card-head">
      <h1 class="modal-card-title mw-full break-words">
        {{ authUser.firstname }}, parlez-nous un peu de vous
      </h1>
    </header>
    <div ref="modalBody" class="modal-card-body is-relative">
      <b-progress
        class="compoq_progress"
        type="is-primary"
        :max="questions.length"
        :value="currentQuestion + 1"
      />
      <div
        v-for="(one, id) in $options.static.questions"
        :key="one.name"
        v-show="currentQuestion == id">
        <div class="field pt-5">
          <h2 class="title is-4">
            {{ $options.static.messages[one.name] }}
          </h2>

          <b-field v-if="one.type === 'select'" :label-for="`field-${id}`">
            <b-select
              v-model.trim="questions[id].value"
              @input="hasError = false"
              :id="`field-${id}`"
              expanded>
              <option value="">Choisissez...</option>
              <option v-for="(option, k) in one.options" :key="k" :value="k">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <div v-else-if="one.type === 'radio'" class="columns is-multiline mb-5">
            <div v-for="(option, k) in one.options" :key="k" class="column is-4">
              <label
                :for="`choice-${k}-${id}`"
                style="min-height: 100px;"
                class="compoq_choice box p-3 h-full cursor-pointer is-unselectable"
                :class="{'has-background-primary has-text-white': k === questions[id].value }">
                <input
                  class="is-sr-only"
                  type="radio"
                  :id="`choice-${k}-${id}`"
                  v-model.trim="questions[id].value"
                  @input="hasError = false"
                  :name="one.name"
                  :value="k">
                <span class="has-text-weight-bold" v-html="option" />
              </label>
            </div>
          </div>
          <div v-else-if="one.type === 'checkbox'" class="has-text-weight-normal is-size-8-mobile">
            <p v-for="(option, k) in one.options" :key="k" class="py-2">
              <b-checkbox
                v-model.trim="questions[id].value"
                @input="hasError = false"
                :native-value="k"
                expanded>
                {{ option }}
              </b-checkbox>
            </p>
          </div>
        </div>
        <b-field :label-for="`other-${id}`">
          <template #label>
            <template v-if="one.name != 'WHAT_TOOL'">
              Donnez-nous quelques détails
            </template>
            <template v-else>
              <template v-if="questions[id].value.indexOf('NOTHING') >= 0">
                Avez-vous déjà testé d'autres outils ? (facultatif)
              </template>
              <template v-else>
                Précisez l'outil
              </template>
            </template>
          </template>
          <b-input
            :id="`other-${id}`"
            v-model="questions[id].other"
            @input="hasError = false"
            :placeholder="`${
              (
                questions[id].value.indexOf('OTHER') < 0
                && one.name != 'WHAT_TOOL'
              ) ? '(facultatif)' : ''
            }`"
          />
        </b-field>
        <p v-show="hasError" class="help is-danger">
          Ah, vous avez oublié quelque chose !
        </p>
      </div>
    </div>
    <footer
      class="modal-card-foot has-background-light"
      :class="{'is-justify-content-flex-end': !isMobileScreen}">
      <b-button v-show="currentQuestion > 0" type="is-text" @click="prevQuestion">
        Précédent
      </b-button>
      <b-button
        v-show="currentQuestion < questions.length - 1"
        type="is-primary"
        @click="nextQuestion">
        Suivant
      </b-button>
      <b-button
        v-show="currentQuestion == questions.length - 1"
        native-type="submit"
        type="is-primary"
        :loading="isLoading">
        Envoyer
      </b-button>
    </footer>
  </form>
</template>

<style lang="scss" scoped>
.compoq {
  &_choice:hover {
    background-color: $theme_color_primary_light;
  }

  &_progress {
    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;

    ::v-deep progress {
      border-radius: 0 !important;
    }
  }
}
</style>
