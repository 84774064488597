<script>
export default {
  methods: {
    handleSeeMore() {
      this.$router.push({ name: 'plan', query: { onglet: 'services' } });
      this.$emit('close');
    },
  },
};
</script>

<template>
    <AppCardModal
        tag="div"
    >
    <template #title>
      NOUVEAU ! Bénéficiez d'un audit de lancement personnalisé
    </template>
    <template #body>
      <p>
        Vous cherchez des conseils pour améliorer rapidement vos contenus et vidéos{{'\xa0'}}?
        <br/><br/>
        Faites analyser votre formation par notre référente pédagogique experte,
        obtenez un rapport complet & des recommandations concrètes pour vous lancer sereinement.
      </p>
    </template>
    <template #foot>
      <b-button
        type="is-primary is-custom"
        native-type="button"
        @click="handleSeeMore"
      >
        En savoir plus
      </b-button>
    </template>
  </AppCardModal>
</template>
