import Vue from 'vue';
import { env } from '@shared/env';

const constants = {
  WEBSITE_GA: 'G-Z1K4WDED3F',
  COACHING_URL: 'https://forms.gle/M1Mg4sF7VEmnZSNe7',
  YT_URL: 'https://www.youtube.com/channel/UC5LjnScHvtvd4bHo3b3hUuw',
  SERVER_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  APP_MONTHLY_PRICE: 49,
  APP_YEARLY_PRICE: 490,
  APP_COMMISSION: 9,
  PRICES: {
    MONTHLY: {
      PRO: 49,
      EXPERT: 99,
      EXPERT_REAL: 129,
      USER_ACCOUNT: 29,
    },
    YEARLY: {
      PRO: 490,
      EXPERT: 990,
      EXPERT_REAL: 1290,
      USER_ACCOUNT: 290,
    },
  },
  DISCOUNT_LEVELUP: 10,
  AFFILIATE_PERCENT: 20,
  MAX_VIDEO_SIZE: 512 * 1024 * 1024,
  MAX_VIDEO_SIZE_PRO: 1024 * 1024 * 1024,
  MAX_AUDIO_SIZE: 512 * 1024 * 1024,
  MAX_PDF_SIZE: 200 * 1024 * 1024,
  MAX_QUIZ_MEDIA_SIZE: 200 * 1024 * 1024,
  MAX_GENERAL_MEDIA_SIZE: 200 * 1024 * 1024,
  MAX_IMAGE_SIZE: 5 * 1024 * 1024,
  MAX_RESOURCE_SIZE: 512 * 1024 * 1024,
  MAX_ASSIGNMENT_SIZE_PRO: 30 * 1024,
  MAX_ASSIGNMENT_SIZE_EXPERT: 60 * 1024,
  CSV_FORMATS: [
    '.csv',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/comma-separated-values',
  ].join(','),
  icons: {
    SECTION: 'folder',
    GENERIC: 'file-invoice',
    VIDEO: 'video',
    QUIZ: 'tasks',
    TASKS: 'clipboard-check',
    ASSIGNMENT: 'file-archive',
    SCORM: 'suitcase',
  },
  roles: ['ADMIN', 'TEACHER', 'ANALYST'],
  BUYABLE_TYPE: {
    TRAINING: 'TRAINING',
    BUNDLE: 'BUNDLE',
  },
  GROUP_AUTO_ADD: {
    ALL: 'ALL',
    TRAININGS: 'TRAININGS',
    MANUAL: 'MANUAL',
  },
  AFFILIATE_PREFIX: 'REF-',
  LSKEY_AFFILIATE_CODES: '___aflctk',
  LSKEY_AFFILIATE_LAST_VISIT: '___afllvtk',
  LSKEY_TRAINING_ITEM_CONFIRM_COUNT: '___tnitcfck',
  LSKEY_TRAINING_ITEM_HIDE_CONFIRM: '___tnithcfk',
  LSKEY_UTM_SOURCE: '__tz_utm_source',
  LSKEY_UTM_MEDIUM: '__tz_utm_medium',
  LSKEY_UTM_CAMPAIGN: '__tz_utm_campaign',
  LSKEY_UTM_ID: '__tz_utm_id',
  LSKEY_UTM_TERM: '__tz_utm_term',
  LSKEY_UTM_CONTENT: '__tz_utm_content',
  AI_CONTENT_TYPES: [
    'title_ideas',
    'value_proposition',
    'outline',
    'sales_page',
  ],
  AI_PRICE_IDS: env.stripeAIPrices,
  AI_PRICES: {
    REAL: {
      PACK_S: 19,
      PACK_M: 39,
      PACK_L: 99,
    },
    PROMO: {
      PACK_S: 12,
      PACK_M: 24,
      PACK_L: 60,
    },
    CREDITS: {
      PACK_S: 40,
      PACK_M: 80,
      PACK_L: 200,
    },
    GIFT: {
      PACK_S: 0,
      PACK_M: 20,
      PACK_L: 50,
    },
  },
};

Vue.prototype.$constants = constants;

export default constants;
